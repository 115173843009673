export const adPromotionStatus = [
    {
        value: 'NOT_DELETED',
        text: '不限'
    },
    {
        value: 'ALL',
        text: '不限（包含已删除）'
    },
    {
        value: 'OK',
        text: '投放中'
    },
    {
        value: 'DELETED',
        text: '已删除'
    },
    {
        value: 'PROJECT_OFFLINE_BUDGET',
        text: '项目超出预算'
    },
    {
        value: 'PROJECT_PREOFFLINE_BUDGET',
        text: '项目接近预算'
    },
    {
        value: 'TIME_NO_REACH',
        text: '未到达投放时间'
    },
    {
        value: 'TIME_DONE',
        text: '已完成'
    },
    {
        value: 'NO_SCHEDULE',
        text: '不在投放时段'
    },
    {
        value: 'AUDIT',
        text: '新建审核中'
    },
    {
        value: 'REAUDIT',
        text: '修改审核中'
    },
    {
        value: 'FROZEN',
        text: '已终止'
    },
    {
        value: 'AUDIT_DENY',
        text: '审核不通过'
    },
    {
        value: 'OFFLINE_BUDGET',
        text: '广告超出预算'
    },
    {
        value: 'OFFLINE_BALANCE',
        text: '账户余额不足'
    },
    {
        value: 'PREOFFLINE_BUDGET',
        text: '广告接近预算'
    },
    {
        value: 'DISABLED',
        text: '已暂停'
    },
    {
        value: 'PROJECT_DISABLED',
        text: '已被项目暂停'
    },
    {
        value: 'LIVE_ROOM_OFF',
        text: '关联直播间不可投'
    },
    {
        value: 'PRODUCT_OFFLINE',
        text: '关联商品不可投'
    },
    {
        value: 'AWEME_ACCOUNT_DISABLED',
        text: '关联抖音账号不可投'
    },
    {
        value: 'AWEME_ANCHOR_DISABLED',
        text: '锚点不可投'
    },
    {
        value: 'DISABLE_BY_QUOTA',
        text: '已暂停（配额达限）'
    },
    {
        value: 'CREATE',
        text: '新建'
    },
    {
        value: 'ADVERTISER_OFFLINE_BUDGET',
        text: '账号超出预算'
    },
    {
        value: 'ADVERTISER_PREOFFLINE_BUDGET',
        text: '账号接近预算'
    },
]