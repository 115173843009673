<template>
  <a-card class="content">
    <div class="top-header">
      <a-page-header title='广告管理' />
      <base-button
        :title="'返回'"
        @onClickBtn="handleBack"
        style="margin-left: 30px"
      ></base-button>
    </div>
    <a-form-model class="form" ref="form" :model="form" v-bind='layout'>
      <a-row type="flex" justify="start" style="margin-bottom: -20px">
        <a-col :span="7">
          <a-form-model-item label="查询周期">
            <a-range-picker v-model="pickerTime" value-format="YYYY-MM-DD" :disabled-date="disabledDate"
                            :allow-clear="false" @calendarChange="calendarChange" @openChange="openChange" />

          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="广告主">
            <a-input v-model.trim="form.advertiserName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="巨量项目ID">
            <a-input v-model.trim="form.adProjectId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="广告">
            <a-input v-model.trim="form.adPromotionName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row style="margin-bottom: -24px">
        <a-col :span="7">
          <a-form-model-item label="广告状态">
            <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength="6"
                      v-model="form.adPromotionStatusList" placeholder="请选择" show-search allowClear>
              <a-select-option v-for="item in typeList" :key="item.code" :value="item.code">{{ item.msg
                }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="广告ID">
            <a-input v-model.trim="form.adPromotionId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="隐藏0消费且0转化记录">
            <a-select placeholder="请选择" allowClear v-model="form.hidden">
              <a-select-option key="0">否</a-select-option>
              <a-select-option key="1">是</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="end" style="margin-bottom: -12px">
        <a-button @click="handleReset">重置</a-button>
        <a-button style="margin: 0 5px 0 20px;" type="primary" @click="handleSearch">查询</a-button>
      </a-row>
    </a-form-model>

    <a-table class="Atables" style="margin-top: 20px;" size="small" :loading="tableLoading" :columns="columns"
             :data-source="dataList"
             :rowKey="(record, index) => index" :pagination='false' :scroll="{ x: 2600 }" @change="handleTableChange">
      <div slot="advSlot">
        <div>广告</div>
        <!--<div>巨量项目和广告ID</div>-->
      </div>
      <div slot="adProjectNameSlotTitle">
        <div>巨量项目</div>
      </div>
      <div slot="adProjectName" slot-scope="text, record">
        <div> {{ record.adProjectName || '-' }}</div>
      </div>
      <div slot="adPromotionId" slot-scope="text, record">
        <p v-if="record.adPromotionName  == '合计'">{{ record.adPromotionName || '-' }}</p>
        <a v-else @click="handleJump('materialSwitchManage',record.adPromotionId)">
          {{ record.adPromotionName || '-' }}</a>
        <!--<div class="gray-text" v-show="index !== 0">ID: {{ record.adProjectId }}/{{ record.adPromotionId }}</div>-->
      </div>
      <div slot="operation" slot-scope="text, record, index" style="display:flex;align-items: center;">
        <div v-show="index !== 0">
          <a-button type="link"
                    style="font-size: 12px"
                    @click="handleOpen('detail', record.id, record.adPlatformAccountId, record.adPromotionId)">详情
          </a-button>
          <a-button type="link" @click="handleJump('materialSwitchManage',record.adPromotionId)"
                    style="padding: 0;font-size: 12px">
            素材
          </a-button>
        </div>
        <a-popover title="更多操作" trigger="focus">
          <a-button type="link" v-show="index !== 0" style="font-size: 12px">···</a-button>
          <a-button slot="content" type="link"
                    @click="handleOpen('log', record.id, record.adPlatformAccountId, record.adPromotionId)">操作日志
          </a-button>
        </a-popover>
      </div>
    </a-table>
    <base-pagination :currentPage="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total"
                     @onChange="handlePaginationChange" @onShowSizeChange="handlePaginationChange" />

    <ModalComp v-if="showModal" :openKey="openKey" @closeModal="handleCloseModal" :dataId="dataId"
               :adPlatformAccountId="adPlatformAccountId" :adPromotionId="adPromotionId" />
  </a-card>
</template>

<script>
import moment from 'moment';
import ModalComp from './ModalComp.vue';
import { adPromotionStatus } from './typeList';
import { isEmpty, pick } from 'lodash-es';

export default {
  components: { ModalComp },
  data() {
    const columns = [
      {
        align: 'center',
        fixed: 'left',
        title: '广告平台开关',
        width: 40,
        dataIndex: 'adOptStatus',
        customRender(text) {
          return !text ? '' : text === 'ENABLE' ? '启用' : '禁用';
        },
      },
      {
        align: 'center',
        fixed: 'left',
        title: '广告状态',
        width: 50,
        dataIndex: 'adPromotionStatus',
        customRender(text) {
          return adPromotionStatus.find(val => val.value === text)?.text || '';
        },
      },
      {
        fixed: 'left',
        width: 160,
        align: 'center',
        key: 'adPromotionId',
        dataIndex: 'adPromotionId',
        slots: { title: 'advSlot' },
        scopedSlots: { customRender: 'adPromotionId' },
      },
      {
        fixed: 'left',
        width: 160,
        align: 'center',
        key: 'adProjectName',
        dataIndex: 'adProjectName',
        slots: { title: 'adProjectNameSlotTitle' },
        scopedSlots: { customRender: 'adProjectName' },
      },
      {
        align: 'center',
        fixed: 'left',
        title: '广告主',
        width: 140,
        dataIndex: 'advertiserName',
        customRender(text) {
          return text || '';
        },
      },
      {
        title: '操作',
        fixed: 'left',
        width: 160,
        align: 'center',
        scopedSlots: { customRender: 'operation' },
      },
      {
        align: 'center',
        title: '广告预算',
        dataIndex: 'budget',
        width: 60,
        customRender(text) {
          return text || '-';
        },
      },
      {
        align: 'center',
        title: '出价(元)',
        dataIndex: 'cpaBid',
        width: 60,
        customRender(text) {
          return text || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '消费(元)',
        key: 'statCost',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.statCost || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '目标转化数',
        key: 'convertCnt',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.convertCnt || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '转化单价(元)',
        key: 'convertCost',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.convertCost || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '展示数',
        key: 'showCnt',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.showCnt || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击数',
        key: 'clickCnt',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.clickCnt || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信数',
        key: 'messageAction',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.messageAction || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资数',
        key: 'clueMessageCount',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.clueMessageCount || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资单价（元）',
        key: 'unitPriceMessageAction',
        width: 70,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.unitPriceMessageAction || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '表单提交数',
        key: 'form',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.form || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '千次展示单价（元）',
        key: 'unitPricePerThousandShow',
        width: 70,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.unitPricePerThousandShow || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '展示转化率(%)',
        key: 'showConvertRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.showConvertRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击率(%)',
        key: 'clickRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.clickRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击转化率(%)',
        key: 'clickConvertRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.clickConvertRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击单价（元）',
        key: 'clickUnitPrice',
        width: 70,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.clickUnitPrice || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资率(%)',
        key: 'clueMessageCountRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.clueMessageCountRate || '-';
        },
      },
      {
        align: 'center',
        title: '素材数',
        key: 'adMaterialCount',
        width: 60,
        customRender(text) {
          return text.metric.adMaterialCount || '-';
        },
      },
      {
        align: 'center',
        title: '本日有消费素材数',
        key: 'todayCostAdMaterialCount',
        customRender(text) {
          return text.metric.todayCostAdMaterialCount || '-';
        },
      },
    ];
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 15 },
      },
      form: {
        advertiserName: undefined,
        adPorjectName: undefined,
        adPromotionStatusList: [],
        hidden: '0',
        adPromotionId: undefined,
      },
      pickerTime: [moment(), moment()],
      selectStartDay: '',
      tableLoading: false,
      columns,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      dataList: [],
      typeList: [],
      showModal: false,
      openKey: 'detail',
      dataId: '',
      adPlatformAccountId: '',
      adPromotionId: '',
    };
  },
  created() {
    if (this.$route.params.adProjectId || this.$route.query.id || this.$route.query.DATA_TIME) {
      this.form.adProjectId = this.$route.params.adProjectId;
      this.form.adPromotionId = this.$route.query.id;
      this.pickerTime = this.$route.query.DATA_TIME;
    } else if (this.$route.params.adPromotionId) {
      this.form.adPromotionId = this.$route.params.adPromotionId;
    } else {
      try {
        const params = JSON.parse(sessionStorage.getItem('__advertising_manage__') || '{}');
        if (!isEmpty(params)) {
          this.form = { ...this.form, ...pick(params, ['adPromotionName', 'adPromotionStatusList', 'advertiserName', 'adPromotionId', 'adProjectId', 'hidden']) };
          if (params.startTime) this.pickerTime = [moment(params.startTime), moment(params.endTime)];
          // this.sortList = params.sortList;
          this.pagination.current = params.page;
          this.pagination.pageSize = params.size;
        }
      } finally {
      }
    }
    this.$nextTick(this.init);
  },
  methods: {
    async init() {
      await this.getProjectTypeList();
      await this.getDataList();
    },
    async getProjectTypeList() {
      const callBackFn = (data) => {
        this.typeList = data;
        if (data.length) {
          const params = JSON.parse(sessionStorage.getItem('__advertising_manage__') || '{}');
          if (!isEmpty(params?.adPromotionStatusList) && !this.$route.params.adProjectId && !this.$route.params.adPromotionId) {
            this.form.adPromotionStatusList = params.adPromotionStatusList;
          } else {
            // 默认选中
            this.form.adPromotionStatusList = this.typeList.filter(v => (v.code !== 'DELETED')).map((value) => (value.code));
          }
        }
      };
      await this.handleRequest('getAdvTypeList', (data) => callBackFn(data));
    },
    async getDataList() {
      const startTime = `${ moment(this.pickerTime[0]).format('YYYY-MM-DD') } 00:00:00`;
      const endTime = `${ moment(this.pickerTime[1]).format('YYYY-MM-DD') } 23:59:59`;
      const { adProjectId, adPromotionName, adPromotionStatusList, advertiserName, adPromotionId, hidden } = this.form;
      const params = {
        startTime,
        endTime,
        adProjectId,
        adPromotionName,
        adPromotionStatusList,
        advertiserName,
        adPromotionId,
        hidden,
        sortList: this.sortList,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      sessionStorage.setItem('__advertising_manage__', JSON.stringify(params));
      this.tableLoading = true;
      await this.getDataTotal(params);
      await this.getDataRest(params);
      this.dataList.unshift(this.totalData);
      this.tableLoading = false;
    },
    // 列表合计
    async getDataTotal(params) {
      const param = JSON.parse(JSON.stringify(params));
      delete param.page;
      delete param.size;
      delete param.sortList;
      await this.handleRequest('getAdvManageTotal', (data) => {
        this.totalData = {
          adPromotionName: '合计',
          metric: data,
        };
      }, param);
    },
    // 列表剩余
    async getDataRest(params) {
      const callBackFn = (data) => {
        this.dataList = data.list;
        this.pagination.total = data.total || 0;
      };
      await this.handleRequest('getAdvManageList', (data) => callBackFn(data), params);
    },
    async handleRequest(API, callback, params = null) {
      const { code, message, data } = await this.$api.core.JLAdvertManage[API](params);
      if (code === 200) {
        callback(data);
      } else {
        this.$message.error(`${ message }`);
      }
    },
    disabledDate(current) {
      return current > this.$moment(this.selectStartDay).add(31, 'days') || current < this.$moment(this.selectStartDay).subtract(31, 'days');
    },
    calendarChange(dates, dateStrings) {
      this.selectStartDay = dates[0];
    },
    openChange(status) {
      this.selectStartDay = '';
    },
    handleTableChange(pagination, filters, sorter) {
      const sort = sorter.order ? [{
        fieldName: sorter.columnKey,
        orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
      }] : [];
      this.sortList = sort;
      this.pagination.current = 1;
      this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleOpen(key, dataId, adPlatformAccountId, adPromotionId) {
      this.dataId = dataId;
      this.adPlatformAccountId = adPlatformAccountId;
      this.adPromotionId = adPromotionId;
      this.openKey = key;
      this.showModal = true;
    },
    handleCloseModal() {
      this.showModal = false;
      this.dataId = '';
      this.adPlatformAccountId = '';
      this.adPromotionId = '';
    },
    handleReset() {
      this.form = this.$options.data().form;
      this.form.adPromotionStatusList = this.typeList.filter(v => (v.code !== 'DELETED')).map((value) => (value.code));
      this.pickerTime = [moment(), moment()];
      this.selectStartDay = '';
      this.init();
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    handleJump(name, id) {
      this.$router.push({ name: name, params: { id } });
    },
    handleBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="less">
.gray-text {
  color: #bbbbbb
}

.top-header {
  display: flex;
  align-items: center;
  margin-top: -30px;
}

.Atables /deep/ .ant-table {
  font-size: 10px;
}

.Atables /deep/ .ant-table th,
.Atables /deep/ .ant-table td {
  font-size: 10px;
}
</style>
