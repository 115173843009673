<template>
    <a-form-model :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 9 }">
        <div class="detail-container">
            <div style="color:#b3b9c2">广告平台信息</div>
            <a-form-model-item label="广告">
                {{ form.adPromotionName }}
            </a-form-model-item>
            <a-form-model-item label="广告ID">
                {{ form.adPromotionId }}
            </a-form-model-item>
            <a-form-model-item label="巨量项目ID">
                {{ form.adProjectId }}
            </a-form-model-item>
            <a-form-model-item label="广告创建时间">
                {{ form.promotionCreateTime }}
            </a-form-model-item>
            <a-form-model-item label="广告修改时间">
                {{ form.promotionModifyTime }}
            </a-form-model-item>
            <a-form-model-item label="平台账套ID">
                {{ form.adPlatformAccountId }}
            </a-form-model-item>
            <a-form-model-item label="抖音号">
                {{ form.awemeId }}
            </a-form-model-item>
            <a-form-model-item label="落地页URL">
                <div v-for="(value, index) in form.externalUrlMaterialList" :key="index">
                    <a-button type="link" @click="handleJump(value)">{{ value }}</a-button>
                </div>
            </a-form-model-item>
            <div style="margin-top: 10px; color:#b3b9c2">皮皮慧投定义信息</div>
            <a-form-model-item label="是否托管">
                {{ form.isSysControl ? '是' : '否' }}
            </a-form-model-item>
            <a-form-model-item label="广告主">
                {{ form.advertiserName }}
            </a-form-model-item>
        </div>
    </a-form-model>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default: {}
        }
    },
    methods: {
        handleJump(url) {
            window.open(url, '_blank')
        }
    }
}
</script>

<style scoped lang="less">
.detail-container {
    min-height: 720px;
}

/deep/ .ant-form-item {
    margin-bottom: 0;
}
</style>